import { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import LanguageIcon from "@mui/icons-material/Language";
import GitHubIcon from "@mui/icons-material/GitHub";

import "./connect.css";

function Connect() {
  const [isVerified, setIsVerified] = useState(null);
  const [onload, setOnload] = useState(false);
  const [RecaptchaComponent, setRecaptchaComponent] = useState(null);

  useEffect(() => {
    const loadRecaptcha = async () => {
      const { default: ReCAPTCHA } = await import("react-google-recaptcha");
      setRecaptchaComponent(() => ReCAPTCHA);
      setOnload(true);
    };

    loadRecaptcha();
  }, []);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    const sendPromise = emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      form.current,
      {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      }
    );

    // const sendPromise = new Promise((resolve) => {
    //   setTimeout(() => {
    //     console.log("Email sent successfully");
    //     resolve();
    //   }, 2000);
    // });

    toast.promise(sendPromise, {
      loading: "Sending email...",
      success: "Thank you! Your email has been sent successfully.",
      error: "Oops! There was an error sending the email.",
    });

    sendPromise.then(() => {
      form.current.reset();
    });

    setIsVerified(null);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div
        className="row contact-form-container"
        data-aos="fade-right"
        data-aos-delay="200"
      >
        <div className="col-md-8 contact-form">
          <form ref={form} onSubmit={sendEmail}>
            <label htmlFor="user_name">Your Name</label>
            <input
              type="text"
              name="user_name"
              id="user_name"
              placeholder="Enter your full name"
              required
            />
            <label htmlFor="user_email">Your Email</label>
            <input
              type="email"
              name="user_email"
              id="user_email"
              placeholder="Enter your email address"
              required
            />
            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              id="message"
              placeholder="Leave your message here"
              required
            />
            <input
              className="submit-btn"
              type="submit"
              id="submit-btn"
              disabled={!isVerified}
              value="Send Message"
            />
            {onload ? (
              <RecaptchaComponent
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={() => setIsVerified(true)}
              />
            ) : (
              <div>Loading reCAPTCHA...</div>
            )}
          </form>
        </div>
        <div className="col-md-3"></div>
      </div>
      <div className="row icon-list">
        <div className="icon-item">
          <a
            href="https://www.linkedin.com/in/vivek-k42/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon />
          </a>
        </div>
        <div className="icon-item">
          <a
            href="https://github.com/vivek2293"
            target="_blank"
            rel="noreferrer"
          >
            <GitHubIcon />
          </a>
        </div>

        <div className="icon-item">
          <a
            href="https://twitter.com/Vivek2943"
            target="_blank"
            rel="noreferrer"
          >
            <XIcon />
          </a>
        </div>
        <div className="icon-item">
          <a
            href="https://www.instagram.com/vivek._k42"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
        </div>
        <div className="icon-item">
          <a href="/">
            <LanguageIcon />
          </a>
        </div>
        <div className="copyright">
          &copy; 2024 copyright <i>Vivek Kumar</i> all right reserved.
        </div>
      </div>
    </>
  );
}

export default Connect;
