import React from "react";
import "./aboutme.css";

const AboutMeCard = () => {
  const aboutMeData = {
    para1:
      "I am a passionate Full Stack Developer with expertise in Full Stack Development, Search Engine Optimization(SEO), Performance Tuning, Security, and Data Visualization. My technical skills include JavaScript, React, Node.js, MongoDB, and more. I excel in problem-solving and have a keen eye for optimizing performance and enhancing security measures. I thrive in collaborative environments and am committed to delivering top-notch results in every project.",
    para2:
      "I keep polishing my programming and problem-solving skills by actively participating in competitive coding platforms like Codeforces and Codechef. I also enjoy learning and optimizing algorithms and building complex systems, finding it deeply satisfying to solve tough problems in software engineering. I'm now keen on exploring backend infrastructure and microservices in-depth, aiming to understand how to create scalable and reliable systems that drive modern applications.",
    para3:
      "I'm open to collaboration. If you're interested in working together, feel free to reach out.",
    skills: [
      "Full Stack Development",
      "SEO",
      "PWAs",
      "Automation",
      "Performance Tuning",
      "Security",
      "Data Visualisation",
      "Testing",
      "Machine Learning",
      "Data Analysis",
    ],
  };

  return (
    <div className="row about-me" data-aos="fade-right">
      <div className="exp-row about-me-card">
        <p>{aboutMeData.para1}</p>
        <p>{aboutMeData.para2}</p>
        <p>
          {aboutMeData.para3} <a href="#connect">here</a>
        </p>
        <p>My expertise includes:</p>
        <div className="button-container">
          {aboutMeData.skills &&
            aboutMeData.skills.map((skill, index) => (
              <button key={index} className="btn">
                {skill}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AboutMeCard;
