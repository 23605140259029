import { useEffect } from 'react'
import Navbar from './components/Navbar'
import Main from './components/Main'
import Skillsection from './components/Skillsection';
import InternshipExperienceCard from './components/InternshipExperienceCard'
import POR from './components/POR'
import Awardcard from './components/Awardcard'
import Projectcard from './components/Projectcard'
import Connect from './components/Connect'
import Resume from './components/Resume'
import AboutMe from './components/AboutMe';
import Aos from 'aos';
import 'aos/dist/aos.css'

import './App.css';

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1200
    });
  }, [])

  return (
    <>
      <Navbar />
      <main>
        <div id="home">
          <Main />
        </div>
        <hr id="bio" />
        <div>
        <h2 className='topic'>About Me</h2>
          <AboutMe />
        </div>
        <div>
          <h2 className='topic'>Experience</h2>
          <InternshipExperienceCard />
        </div>
        <div>
          <h2 className='topic'>Tools and Technologies</h2>
          <Skillsection />
        </div>
        <div>
          <h2 className='topic'>Position of Responsibility</h2>
          <POR />
        </div>
        <hr id="projects"/>
        <div>
          <h2 className='topic'>My Projects</h2>
          <Projectcard />
        </div>
        <hr id="recognitions" />
        <div>
          <h2 className='topic'>Achievements</h2>
          <Awardcard />
        </div>
        <hr id="resume"/>
        <div>
          <h2 className='topic'>Resume</h2>
          <Resume />
        </div>
        <hr id="connect"/>
        <div>
          <h2 className='topic'>Let's Connect</h2>
          <Connect />
        </div>
      </main>
    </>
  );
}


export default App;