import "./por.css"

function POR() {
    const arr = [
        {
            title: "Problem Setter",
            label: "LCS",
            p: "Aug 2023 - Present",
            desc: "Learn Code Solve",
            content: "I am currently a problem setter at LCS Club, which mentors students and enhances their problem-solving skills in a supportive environment. I curate and test problems for each contest, ensuring they are balanced and challenging. Additionally, I oversee contest operations to ensure everything runs smoothly."
        },
        {
            title: "Core Developer",
            label: "GDSC, IIIT Surat",
            p: "July 2023 - Present",
            desc: "Google Developer Student Club",
            content: "As a core developer in GDSC, we collectively collaborate with peers, and mentor fellow students to develop technical solutions to the real-world problems."
        },
        {
            title: "Mentor",
            label: "Coding Commmittee, Spring Fiesta 2024",
            p: "March 2024 - April 2024",
            desc: "Spring Fiesta 2024",
            content: "Organized and facilitated two separate competitions: a 3v3 team battle in coding and an ArtXAI comic book creation workshop. Developed questions for the contests and provided mentorship to participants. Successfully managed the events, ensuring they ran smoothly and efficiently."
        },
        {
            title: "Junior Developer",
            label: "GDSC, IIIT Surat",
            p: "Sept 2022 - July 2023",
            desc: "Google Developer Student Club",
            content: "As a junior developer in GDSC, I contributed to various projects by writing clean and efficient code, debugging issues, implementing new features, and collaborating with senior developers to deliver high-quality software solutions."
        }
    ];

  return (
    <>
        <div className="row">
            {
                arr.map((ele, ind) => {
                    return(
                        <div className="exp-row" data-aos="fade-up" key={ind}>
                            <div className="row-sp-1">
                                <div className="col-md-4">
                                    <div className="col-exp-left">
                                    <h4>{ele.title}</h4>
                                    <p className='label'>{ele.label}</p>
                                    <p>{ele.p}</p>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="col-exp-right">
                                    <h4>{ele.desc}</h4>
                                    <p>{ele.content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    </>
  )
}

export default POR