import { useState, useEffect } from "react";
import "./resume.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function Resume() {
  const [pdfUrl, setPdfUrl] = useState(null);
  const newPlugin = defaultLayoutPlugin();

  useEffect(() => {
    fetch("/Resume_Vivek_Kumar.pdf")
      .then((response) => response.blob())
      .then((blob) => {
        const pdfUrl = URL.createObjectURL(blob);
        setPdfUrl(pdfUrl);
      });
  }, []);
  return (
    <div className="resume_section" data-aos="zoom-in">
      <div className="content">
        {!pdfUrl ? (
          <p>Loading resume viewer...</p>
        ) : (
          <>
            <div className="drive-card">
              <p>
                Alternative Drive link:{" "}
                <a
                  alt="drive link for resume"
                  href="https://drive.google.com/file/d/1gkju_VdBtkd1IzfyWwPX2uQMA5Kelv6a/view?usp=drive_link"
                >
                  Link
                </a>
              </p>
            </div>
            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
              <Viewer fileUrl={pdfUrl} plugins={[newPlugin]} theme="dark" />
            </Worker>
          </>
        )}
      </div>
    </div>
  );
}

export default Resume;
