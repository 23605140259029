import React from "react";
import "./internshipExp.css";

const InternshipExperienceCard = () => {
  const internshipData = {
    title: "Arizon Systems | Full Stack Developer Intern",
    duration: "April 2024 - June 2024",
    responsibilities: [
      "Managed the backend for the Calibration Management System of one of India's largest pharmaceutical manufacturing companies. Additionally, developed software to automate the financial analysis of <span class='highlight'>XBRL 10-K files</span>.",
      "Utilized <span class='highlight'>Prisma ORM</span> to streamline database migrations, and developed a database backup and recovery solution for the application.",
      "Integrated <span class='highlight'>Jaeger</span> and <span class='highlight'>Prometheus</span> to identify and resolve API performance bottlenecks, resulting in an average reduction of import time by 3.57x and critical API response time by 1.92x.",
      "Implemented unit testing using <span class='highlight'>Jest</span> for critical calculative functions (50+) in the ReactJS application.",
      "Crafted a load and stress testing script for a complete single calibration flow with over 30 API calls to validate performance under heavy usage and stress conditions.",
    ],
  };

  return (
    <div className="row internship-experience" data-aos="fade-up">
      <div className="exp-row internship-card">
        <h3>{internshipData.title}</h3>
        <h4>{internshipData.duration}</h4>
        <ul>
          {internshipData.responsibilities.map((responsibility, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: responsibility }} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default InternshipExperienceCard;
