import { useState, useEffect } from 'react';
import "./projectcard.css"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import aim_bot from '../images/pro_card/aim_bot.jpg';
import algo from '../images/pro_card/algo_opt.jpg';
import tp from '../images/pro_card/training_and_placement.jpg';
import steg from '../images/pro_card/steganography.jpg';
import sale from '../images/pro_card/sales_tracker.avif';
// import calc from '../images/pro_card/calculator.jpg';
import scrap from '../images/pro_card/scrapper.jpg';
import medilock from '../images/pro_card/medilocker.avif';
// import blog from '../images/pro_card/blog.jpg';
import rest from '../images/pro_card/restaurant.jpg';
import whatsapp from '../images/pro_card/whatsapp_bot.avif';

function Projectcard() {
    const [pro_arr, setPro_arr] = useState([]);
    useEffect(() => {
        const data = [
            {
                img: aim_bot,
                title: "AI Driven Game Automation",
                text: "Implemented Reinforcement Learning algorithm to train a bot in the game Modern Warships autonomously.",
                tags: ["Pyautogui", "OpenCV", "PIL", "Numpy", "Reinforcement Learning"],
                link: "https://github.com/vivek2293/AI-Driven-Game-Automation"
            },
            {
                img: tp,
                title: "Hireminds",
                text: "Streamlining T&P for students and companies, enhancing efficiency with a user-friendly platform for seamless interactions.",
                tags: ["ReactJS", "NodeJS", "JWT", "Nodemailer", "Full-Stack Application"],
                link: "https://github.com/vivek2293/Hireminds"
            },
            {
                img: algo,
                title: "Linear Regression Algorithm Optimization",
                text: "Efficient linear regression optimization, reducing the running time complexity of convergence considerably by a huge factor for large datasets.",
                tags: ["Python", "Jupter Notebook", "Alogrithm Optimization"],
                link: "https://github.com/vivek2293/Optimizing_linear_regression"
            },
            {
                img: sale,
                title: "Project Upsales",
                text: "Sales tracking, predictive insights, and visual analytics for informed decision-making in businesses.",
                tags: ["ReactJS", "NodeJS", "Python", "Bootstrap", "ML" ],
                link: "https://github.com/vivek2293/Project_UpSales"
            },
            {
                img: steg,
                title: "PixelCipher",
                text: "Steganography tool ensuring secure communication through text-to-text and text-to-image encryption and alteration detection capabilities.",
                tags: ["Python", "Text Encryption", "Image Processing", "Steganography"],
                link: "https://github.com/vivek2293/PixelCipher"
            },
            {
                img: scrap,
                title: "Web Scrapper",
                text: "Python script that scrapes diverse reviews, gathering valuable data for user insights.",
                tags: ["Python", "Data Mining"],
                link: "https://github.com/vivek2293/Web_scrapper"
            },
            
            {
                img: medilock,
                title: "MediLocker",
                text: "A secure medical document application inspired by Digilocker for data protection and accessibility.",
                tags: ["ReactJS", "NodeJS", "JWT", "Firebase"],
                link: "https://github.com/vivek2293/MediLocker"
            },
            // {
            //     img: calc,
            //     title: "Cross-platform calculator",
            //     text: "Cross-platform React calculator app for seamless mathematical calculations on-the-go.",
            //     tags: ["ReactJS", "React Native"],
            //     link: "https://github.com/vivek2293/Calculator"
            // },
            {
                img: rest,
                title: "GDSC Food Corner",
                text: "Restaurant solution: QR orders, separate billing for groups, admin-side order placement, and efficient GST invoicing.",
                tags: ["HTML", "CSS", "JavaScript", "EJS", "NodeJS"],
                link: "https://github.com/vivek2293/GDSC-Food-Corner"
            },
            // {
            //     img: blog,
            //     title: "Digital Wanderers",
            //     text: "Blog site that covers the latest trends, emerging technologies, and innovations in the digital space.",
            //     tags: ["HTML", "Bootstrap"],
            //     link: "https://github.com/vivek2293/Digital_wanderers"
            // },
            {
                img: whatsapp,
                title: "WhatsApp Bot",
                text: "WhatsApp bot for automated responses, providing quick and efficient customer service.",
                tags: ["Facebook Graph API", "NodeJS", "ExpressJS"],
                link: "https://github.com/vivek2293/Whatsapp_mirrorbot"
            }
        ];

        setPro_arr(data);
    }, []);
    
  return (
    <>
        <div className="row project-row">
            {   pro_arr.length > 0 &&
                pro_arr.map((ele,ind) => {
                    return (
                        <div className="col-md-6" data-aos="fade-up" key={ind}>
                            <div className="card-pro">
                                <div className="card-pro-img">
                                    <div className="card-img" style={{
                                        backgroundImage: `url(${ele.img})`,
                                    }}>
                                    </div>
                                </div>

                                <div className="card-pro-content">
                                    <h4>{ele.title}</h4>
                                    <p>{ele.text}</p>
                                    <div className="btn-desc">
                                        {
                                            ele.tags.map((el, id)=>{
                                                return(
                                                    <button className='info' key={id}>{el}</button>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className='link-btn'>
                                        <a href={ele.link} target='_blank' rel="noreferrer">
                                            <button className='info githublink'>GITHUB <OpenInNewIcon className='link-icon'/> </button>
                                        </a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    );
                })
            }
            
        </div>
    </>
  )
}

export default Projectcard