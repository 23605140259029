import { useState } from 'react';
import './tooltip.css';

const Tooltip = ({ tooltipContent, children }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const showTooltip = (event) => {
    setTooltipPosition({
      top: Math.min(event.clientY + 10, window.innerHeight - 25),
      left: Math.min(event.clientX + 10, window.innerWidth - 100),
    });
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };
  return (
    <div className="tooltip-container" onMouseMove={showTooltip} onMouseOut={hideTooltip}>
      {children}
      {tooltipVisible && (
        <div className="tooltip" style={tooltipPosition}>
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
