import "./awardCard.css"
import demo from "../icons/award.webp"

function Awardcard() {
  const award_arr = [
	{
		img: demo,
		title: "3x ICPC Regionalist",
		text: "Issued by International Collegiate Programming Contest."
	},
    {
		img: demo,
		title: "1st Rank in HASHCUP 1.0",
		text: "Issued by LCS IIIT Surat."
    },
	{
		img: demo,
		title: "1st Rank in ALGOHOURS 3.0",
		text: "Issued by LCS IIIT Surat."
	},
	{
		img: demo,
		title: "Global Rank 40 in TCS Codevita",
		text: "Hosted by TCS."
	},
	{
		img: demo,
		title: "Global Rank 28 in Starters 102 Division 2",
		text: "Hosted By Codechef."
	},
	{
		img: demo,
		title: "Global Rank 203 in Codeforces Round 925",
		text: "Hosted by Codeforces."
	},
	{
		img: demo,
		title: "Top 8 in Deveheat Hackathon",
		text: "Organised by GDSC, IIIT Surat."
	},
	{
		img: demo,
		title: "3rd Rank in CodeRush Challenge 2023",
		text: "Issued by AlgoUniversity."
	},
	{
		img: demo,
		title: "NTSE Scholar",
		text: "Issued by National Council of Educational Research and Training (NCERT)."
	}
  ]
  return (
    <>
      <div className='row'>
        {
			award_arr.map((ele, ind) => {
				return(
					<div className='card-container card-width-award' data-aos="fade-right" key={ind}>
						<div className='card-logo' style={{backgroundImage: `url(${ele.img})`}}>
						</div>
						<div className='card-award-content'>
							<div className='card-award-title'>{ele.title}</div>
							<div className='card-award-issuer'>{ele.text}</div>
						</div>
					</div>
				);
			})
		}
      </div>
    </>
  )
}

export default Awardcard