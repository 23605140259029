import HomeIcon from '@mui/icons-material/Home';
import TaskIcon from '@mui/icons-material/Task';
import PersonIcon from '@mui/icons-material/Person';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import "./navbar.css"
import { HashLink as Link } from 'react-router-hash-link';
import Tooltip from './Tooltip';

function Navbar() {
  return (
    <>
      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="nav-item" id="nav-logo-field">
            <Link to="#" className="nav-link">
              <span className="nav-logo">
                V
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <Tooltip tooltipContent={"Home"}>
            <Link to="/#home" className="nav-link">
              <span className="link-icon">
                <HomeIcon className='sx-nav'/>
              </span>
            </Link>
            </Tooltip>
          </li>
          <li className="nav-item">
            <Tooltip tooltipContent={"About Me"}>
            <Link to="/#bio" className="nav-link">
              <span className="link-icon">
                <PersonIcon className='sx-nav'/>
              </span>
            </Link>
            </Tooltip>
          </li>
          <li className="nav-item">
            <Tooltip tooltipContent={"Projects"}>
            <Link to="/#projects" className="nav-link">
              <span className="link-icon">
                <DeveloperBoardIcon className='sx-nav'/>
              </span>
            </Link>
            </Tooltip>
          </li>
          <li className="nav-item">
            <Tooltip tooltipContent={"Achievements"}>
            <Link to="/#recognitions" className="nav-link">
              <span className="link-icon">
                <MilitaryTechIcon className='sx-nav'/>
              </span>
            </Link>
            </Tooltip>
          </li>
          <li className="nav-item">
            <Tooltip tooltipContent={"Resume"}>
            <Link to="/#resume" className="nav-link">
              <span className="link-icon">
                <TaskIcon className='sx-nav'/>
              </span>
            </Link>
            </Tooltip>
          </li>
          <li className="nav-item">
            <Tooltip tooltipContent={"Collaborate"}>
            <Link to="/#connect" className="nav-link">
              <span className="link-icon">
                <ConnectWithoutContactIcon className='sx-nav'/>
              </span>
            </Link>
            </Tooltip>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Navbar