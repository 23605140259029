import "./main.css";
import MainTransition from './MainTransition';
import mainImg from "../images/main_img.avif";
import GetAppIcon from '@mui/icons-material/GetApp';
import { toast } from 'react-hot-toast';

function Main() {
  const handleDownload = () => {
    const downloadPromise = async() => {
      const response = await fetch('/Resume_Vivek_Kumar.pdf');
      const blob = await response.blob();
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'Resume_Vivek_Kumar.pdf';
      downloadLink.click();
    }

    toast.promise(downloadPromise(), {
      loading: 'Initiating...',
      success: 'Ready for download.',
      error: 'Oops! There was an error generating the link. Please try again.'
    });      
  };

  return (
    <>
    <div className="row main_page">
        <div className="col-md-6 main_content" data-aos="fade-left">
            <div className="main-title">
            <div className='main-title-content'>Hello,</div>
            <div className='main-title-content'>My name is</div>
            <h1 className='main-name'>Vivek Kumar</h1>
            </div>
            <div className="main-sub">
              <MainTransition />
            </div>
            <div className="main-title">
            <button className="resume-download-btn" onClick={handleDownload}> Resume <GetAppIcon /></button>
            </div>
        </div>
        <div className="col-md-4 main_img" data-aos="fade-right" style={{"backgroundImage": `url(${mainImg})`}}>

        </div>
    </div>
    </>
  )
}

export default Main