import { useState, useEffect } from 'react'

function MainTransition() {
    const [content, setContent] = useState("");
    const [row, setRow] = useState(0);
    const [col, setCol] = useState(0);
    const [reverse, setReverse] = useState(0);
    const [speed, setSpeed] = useState(100);

    useEffect(() => {
        const arr = ["Full-Stack Developer  ", "Competitive Progammer  ", "ML Enthusiast  "];
        const timeoutId = setTimeout(() => {
            if(reverse === 0){
                setContent(content + arr[row][col]);
                if(col === arr[row].length - 1){
                    setCol(0);
                    setReverse(1);
                    setRow(row+1);
                    setSpeed(50);
                }
                else setCol(col+1);
            }
            else{
                setContent(content.substring(0, content.length - 1));
                if(content.length === 1){
                    setReverse(0);
                    setSpeed(100);
                    if(row === arr.length){
                        setRow(0);
                    }
                }
            }
        }, speed);

        return () => clearTimeout(timeoutId);
    }, [row, col, reverse, speed, content]);
  return (
    <p className='transition-content'>{content} &nbsp;</p>
  )
}

export default MainTransition