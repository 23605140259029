import './skillsection.css'
import mongodb from '../icons/mongodb.svg'
import express from '../icons/express.svg'
import reactjs from '../icons/reactjs.svg'
import nodejs from '../icons/nodejs.svg'
import python from '../icons/python.svg'
import logo_js from '../icons/javascript-js.svg'
import cplusplus from '../icons/Cplusplus.svg'
import git from '../icons/git.svg'
import bootstrap from '../icons/bootstrap.svg'
import github from '../icons/github.svg'
import sql from '../icons/sql.svg'
import cloudflare from '../icons/cloudflare.svg';
import googlesearchconsole from '../icons/google-search-console.svg';
import jaeger from '../icons/jaeger.svg';
import prisma from '../icons/prisma.svg';

function Skillsection() {
    const arr_icon = [[cplusplus, "fade-right"], [python, "fade-up"], [logo_js, "fade-right"], [mongodb, "fade-down"], [express, "fade-right"], [reactjs, "fade-up"], [nodejs, "fade-right"], [github, "fade-up"],  [bootstrap, "fade-right"], [git, "fade-down"], [sql, "fade-right"], [cloudflare, "fade-up"], [googlesearchconsole, "fade-right"], [jaeger, "fade-down"], [prisma, "fade-right"]];

  return (
    <>
        <div className='skill-container row'>
            <div className="skill-content">
                {
                    arr_icon.map((ele, ind) => {
                        return(
                            <button className='skill-item' data-aos={ele[1]} key={ind}>
                                <img src={ele[0]} alt={ele[0]} loading="lazy" />
                            </button>
                        );
                    })
                }
            </div>
        </div>
    </>
  )
}

export default Skillsection